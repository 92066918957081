
	import { Component, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	// Partners logo
	import IconTrueGrayed from '@pixcap/ui-library/components/Icons/brands/IconTrueGrayed.vue';
	import IconNvidiaGrayed from '@pixcap/ui-library/components/Icons/brands/IconNvidiaGrayed.vue';
	import IconGrabGrayed from '@pixcap/ui-library/components/Icons/brands/IconGrabGrayed.vue';
	import IconAirBnbGrayed from '@pixcap/ui-library/components/Icons/brands/IconAirBnbGrayed.vue';
	import IconESTGrayed from '@pixcap/ui-library/components/Icons/brands/IconESTGrayed.vue';
	import IconMicrosoftGrayed from '@pixcap/ui-library/components/Icons/brands/IconMicrosoftGrayed.vue';
	import IconBharatPeGrayed from '@pixcap/ui-library/components/Icons/brands/IconBharatPeGrayed.vue';
	import IconShopeeGrayed from '@pixcap/ui-library/components/Icons/brands/IconShopeeGrayed.vue';
	import IconAdpListGrayed from '@pixcap/ui-library/components/Icons/brands/IconAdpListGrayed.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';

	@Component({
		name: 'IntroSection',
		components: { IconLargeArrowRight, PixcapLogo, IntersectVideoAutoplay, BodyText, HeadingText },
	})
	export default class IntroSection extends Vue {
		thumbnailUrl = `${process.env.ROOT_URL}/cdn/explore/banner/library-page-banner/assets.mp4`;
		posterUrl = `${process.env.ROOT_URL}/cdn/explore/banner/library-page-banner/assets-poster.webp`;
		LIBRARY_TAB = LIBRARY_TAB;
		partnersLogo = [
			IconNvidiaGrayed,
			IconGrabGrayed,
			IconAirBnbGrayed,
			IconTrueGrayed,
			IconESTGrayed,
			IconMicrosoftGrayed,
			IconBharatPeGrayed,
			IconShopeeGrayed,
			IconAdpListGrayed,
		];
	}
