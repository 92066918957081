
	import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import { HomepageSection } from '@/models/store/organism.interface';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLibraryPack from '@/components/pixcap-library/shared/PixcapLibraryPack.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';

	@Component({
		name: 'HomepagePackList',
		components: { IconLargeArrowRight, PixcapLibraryPack, BodyText, HeadingText, LibraryItemListLoader },
	})
	export default class HomepagePackList extends Vue {
		@Prop() title: string;
		@Prop() description: string;
		@Prop() type: HomepageSection;

		@InjectReactive() windowInnerWidth: number;

		isFetchingPacks = false;
		packItems = [];
		LIBRARY_TAB = LIBRARY_TAB;
		HomepageSection = HomepageSection;

		get headingTitle() {
			if (this.title) return this.title;
			return 'Packs';
		}

		get isMockupList() {
			return [HomepageSection.BRANDING_MOCKUP, HomepageSection.DEVICE_MOCKUP].includes(this.type);
		}

		get resourceType() {
			switch (this.type) {
				case HomepageSection.ICON:
				case HomepageSection.ANIMATED_ICON:
					return [
						LIBRARY_RESOURCE_TYPE.MODELS,
						LIBRARY_RESOURCE_TYPE.FRAMES,
						LIBRARY_RESOURCE_TYPE.TEXT,
						LIBRARY_RESOURCE_TYPE.TEXT_SHAPES,
						LIBRARY_RESOURCE_TYPE.SHAPES,
					];
				case HomepageSection.CHARACTER:
					return [LIBRARY_RESOURCE_TYPE.CHARACTERS];
				case HomepageSection.BRANDING_MOCKUP:
				case HomepageSection.DEVICE_MOCKUP:
					return [LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES];
				default:
					return [];
			}
		}

		get tagQuery() {
			if (this.isMockupList) {
				if (this.type === HomepageSection.BRANDING_MOCKUP) return this.$t('tag_name.brand_kit') as string;
				return this.$t('tag_name.device') as string;
			}
			return undefined;
		}

		get numberOfPackPerPage() {
			return 12;
		}

		async fetchPacks() {
			if (this.isFetchingPacks) return;
			this.isFetchingPacks = true;
			const response = await LibraryActions.fetchPacksList(this.$store, {
				refresh: false,
				page: 0,
				pageSize: this.numberOfPackPerPage,
				skipStore: true,
				isBrandKit: this.isMockupList,
				tagSearch: this.tagQuery,
				hasAnimated: this.type === HomepageSection.ANIMATED_ICON ? true : undefined,
				resourceTypes: this.resourceType,
			});

			if (response) {
				this.packItems = response.content;
			}

			this.isFetchingPacks = false;
		}

		async fetch() {
			await this.fetchPacks();
		}
	}
