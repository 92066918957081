
	import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator';
	import { setupBaseMetaTags } from '@/utils/seo';

	import { HomepageSection } from '@/models/store/organism.interface';

	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import HomepagePackList from '@/components/pixcap-library/library-packs/HomepagePackList.vue';
	import AdvertiseSection from '@/components/homepage/AdvertiseSection.vue';
	import CompatibleSection from '@/components/homepage/CompatibleSection.vue';
	import IntersectComponent from '@pixcap/ui-core/plugins/intersect-component/IntersectComponent.vue';
	import IntroSection from '@/components/homepage/IntroSection.vue';
	import MetricsSection from '@/components/homepage/MetricsSection.vue';
	import CuratedMockupList from '@/components/homepage/CuratedMockupList.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import SignupBanner from '@/components/homepage/SignupBanner.vue';
	import { mapGetters } from 'vuex';
	import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';

	@Component({
		name: 'LibraryExploreList',
		components: {
			SignupBanner,
			HeadingText,
			CuratedMockupList,
			MetricsSection,
			IntroSection,
			IntersectComponent,
			CompatibleSection,
			AdvertiseSection,
			HomepagePackList,
		},
		computed: {
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
		},
	})
	export default class LibraryExploreList extends Vue {
		isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;

		@InjectReactive() isScrolled: boolean;

		@Watch('isAuthenticated')
		handleSignupBanner(val) {
			this.shouldShowSignupBanner = !val;
		}

		LIBRARY_TAB = LIBRARY_TAB;
		HomepageSection = HomepageSection;
		shouldShowSignupBanner = false;

		handleOnEnter() {
			this.shouldShowSignupBanner = false;
		}

		handleOnLeave() {
			if (this.isAuthenticated || !this.isScrolled) {
				return;
			}

			this.shouldShowSignupBanner = true;
		}

		head() {
			const headMeta: any = {
				meta: [],
			};
			const metaTagsPayload: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			metaTagsPayload.title = this.$t('seo_meta.title_library_designs');
			metaTagsPayload.description = this.$t('seo_meta.description_library_designs');
			setupBaseMetaTags(metaTagsPayload, headMeta, this.$route);
			return headMeta;
		}
	}
