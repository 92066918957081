import { render, staticRenderFns } from "./IntroSection.vue?vue&type=template&id=6aa0bf92&scoped=true&"
import script from "./IntroSection.vue?vue&type=script&lang=ts&"
export * from "./IntroSection.vue?vue&type=script&lang=ts&"
import style0 from "./IntroSection.vue?vue&type=style&index=0&id=6aa0bf92&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa0bf92",
  null
  
)

export default component.exports