
	import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_ITEM_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLibraryTemplate from '@/components/pixcap-library/shared/PixcapLibraryTemplate.vue';
	import MasonryWallLoader from '@/components/pixcap-library/shared/MasonryWallLoader.vue';
	import VirtualMasonry from '@/components/pixcap-library/shared/VirtualMasonry.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';

	@Component({
		name: 'CuratedMockupList',
		components: {
			IconLargeArrowRight,
			VirtualMasonry,
			MasonryWallLoader,
			PixcapLibraryTemplate,
			BodyText,
			HeadingText,
		},
	})
	export default class CuratedMockupList extends Vue {
		@InjectReactive() windowInnerWidth: number;
		@InjectReactive() isDesktopScreen: boolean;

		isFetchingItems = false;
		resourceItems = [];
		LIBRARY_TAB = LIBRARY_TAB;
		activeLabelHTML = null;
		componentKey = 0;
		selectedTag: string = this.tagList[0].value;

		$refs: {
			filterHighlight: HTMLInputElement;
		};

		@Watch('selectedTag')
		onSelectedFilterChange() {
			this.activeLabelHTML = document.getElementsByClassName(`tag-label-${this.selectedTag}`)[0];
			switch (this.selectedTag) {
				case this.$t('tag_name.laptop').toLocaleString().toLowerCase():
					this.$refs.filterHighlight.style.left = 'calc(140px + 4px)';
					break;
				case this.$t('tag_name.smartphone').toLocaleString().toLowerCase():
					this.$refs.filterHighlight.style.left = 'calc(140px * 2 + 4px)';
					break;
				case this.$t('tag_name.tablet').toLocaleString().toLowerCase():
					this.$refs.filterHighlight.style.left = 'calc(140px * 3 + 4px)';
					break;
				case this.$t('tag_name.desktops').toLocaleString().toLowerCase():
					this.$refs.filterHighlight.style.left = 'calc(140px * 4 + 4px)';
					break;
				case this.$t('tag_name.branding').toLocaleString().toLowerCase():
					this.$refs.filterHighlight.style.left = 'calc(140px * 5 + 4px)';
					break;
				case this.$t('tag_name.animated').toLocaleString().toLowerCase():
				default:
					this.$refs.filterHighlight.style.left = '4px';
					break;
			}
			if (!this.isDesktopScreen) {
				this.activeLabelHTML.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
			}
			this.componentKey += 1;
			this.resourceItems = [];
			this.fetchItems();
		}

		get tagList() {
			return [
				{
					label: 'Animated',
					value: this.$t('tag_name.animated').toLocaleString().toLowerCase(),
				},
				{
					label: 'Laptop',
					value: this.$t('tag_name.laptop').toLocaleString().toLowerCase(),
				},
				{
					label: 'Phone',
					value: this.$t('tag_name.smartphone').toLocaleString().toLowerCase(),
				},
				{
					label: 'Tablet',
					value: this.$t('tag_name.tablet').toLocaleString().toLowerCase(),
				},
				{
					label: 'Desktop',
					value: this.$t('tag_name.desktops').toLocaleString().toLowerCase(),
				},
				{
					label: 'Branding',
					value: this.$t('tag_name.branding').toLocaleString().toLowerCase(),
				},
			];
		}

		get masonryOptions() {
			if (typeof window !== 'undefined') {
				if (window.innerWidth <= 530) {
					return {
						minColumn: 2,
						columnGap: 24,
						rowGap: 24,
					};
				} else if (window.innerWidth <= 743) {
					return {
						minColumn: 2,
						columnGap: 24,
						rowGap: 24,
					};
				} else if (window.innerWidth <= 1024) {
					return {
						minColumn: 2,
						columnGap: 24,
						rowGap: 24,
					};
				} else if (window.innerWidth >= 1025 && window.innerWidth < 1440) {
					return {
						minColumn: 3,
						columnGap: 24,
						rowGap: 24,
					};
				} else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
					return {
						minColumn: 4,
						columnGap: 24,
						rowGap: 24,
					};
				} else {
					return {
						minColumn: 4,
						columnGap: 24,
						rowGap: 24,
					};
				}
			} else
				return {
					minColumn: 4,
					columnGap: 24,
					rowGap: 24,
				};
		}

		itemHeightGetter(item, colWidth) {
			let calculatedHeight = colWidth;
			if (!this.isDesktopScreen) {
				if (item.animationUrl?.width && item.animationUrl?.height) {
					calculatedHeight = Math.floor((item.animationUrl.height * colWidth) / item.animationUrl.width);
				}
			} else if (item.thumbnailUrl?.width && item.thumbnailUrl?.height) {
				calculatedHeight = Math.floor((item.thumbnailUrl.height * colWidth) / item.thumbnailUrl.width);
			}
			if (calculatedHeight < 100) {
				return 100;
			}
			return calculatedHeight;
		}

		handleShowAll() {
			this.$router.push({
				name: 'LibraryPage',
				params: { tab: LIBRARY_TAB.MOCKUPS },
			});
		}

		async fetchItems() {
			if (this.isFetchingItems) return;
			this.isFetchingItems = true;
			const response = await LibraryActions.fetchLibraryFiles(this.$store, {
				refresh: false,
				page: 0,
				pageSize: 30,
				skipStore: true,
				isFeatured: this.selectedTag === this.$t('tag_name.animated').toLocaleString().toLowerCase() ? true : undefined,
				tagSearch: this.selectedTag === this.$t('tag_name.animated').toLocaleString().toLowerCase() ? undefined : this.selectedTag,
				itemType: [LIBRARY_ITEM_TYPE.ANIMATED_MOCKUP_TEMPLATES, LIBRARY_ITEM_TYPE.STATIC_MOCKUP_TEMPLATES],
				isAnimated: this.selectedTag === this.$t('tag_name.animated').toLocaleString().toLowerCase() ? true : undefined,
			});

			if (response) {
				this.resourceItems = response.content;
			}

			this.isFetchingItems = false;
		}

		async fetch() {
			await this.fetchItems();
		}
	}
