
	import { Component, Vue } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { mapGetters } from 'vuex';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	@Component({
		name: 'SignupBanner',
		components: {
			Button,
			BodyText,
		},
		computed: {
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
		},
	})
	export default class SignupBanner extends Vue {
		handleClickCta() {
			UserMutations.showAuthenticationModal(this.$store, true);
		}
	}
